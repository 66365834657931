import { Injectable } from '@angular/core';
import constants from '../config/constants';
import pageMenus from '../config/page-menus';
import { LocalStorageObject } from '../services/localStorageObject.service';

@Injectable({
  providedIn: 'root'
})
export class PermissoesService {

  constructor(
    private readonly localStorageObject: LocalStorageObject
  ) {
  }

  verificarPermissaoSomenteVisualizar(moduloId: string) {
    const usuario = this.localStorageObject.usuario;
    if (usuario) {
      if (usuario.superAdmin) { return false; }

      const permissao = usuario.permissoes[moduloId];
      const somenteVisualizar = permissao === constants.PERMISSOES.SOMENTE_VISUALIZAR;
      return somenteVisualizar;
    }
  }

  verificarPermissaoAdminModulo(moduloId: string) {
    const usuario = this.localStorageObject.usuario;
    if (usuario) {
      if (usuario.superAdmin) { return false; }

      const permissao = usuario.permissoes[moduloId];
      const adminModulo = permissao === constants.PERMISSOES.ADMIN_MODULO;
      return adminModulo;
    }
  }

  podeExibirModulo(moduloId: string | number) {

    // Se ainda não foi definido regras de permissão para esse menu, pode exibir
    if (!moduloId) { return true; }

    const usuario = this.localStorageObject.usuario;

    if (usuario?.superAdmin) { return true; }

    if (!usuario?.permissoes) { return false; }

    // Verificando e o modulo informado está na listas de permissões do usuário
    const permissao = usuario.permissoes[moduloId];

    const podeExibir = (permissao === constants.PERMISSOES.SOMENTE_VISUALIZAR || permissao === constants.PERMISSOES.CONTROLE_TOTAL || permissao === constants.PERMISSOES.ADMIN_MODULO);

    return podeExibir;
  }

  podeExibirModuloPlano(moduloPlano: string[]): boolean {
    let retorno = false;

    // SuperAdmin
    const usuario = this.localStorageObject.usuario;
    if (usuario?.superAdmin) { return true; }

    // Plano Empresa
    const empresaSelecionada = this.localStorageObject.empresaSelecionada;
    retorno = empresaSelecionada?.planoEmpresa?.plano[moduloPlano[0]] ? true : false;

    return retorno;
  }

  isModuloPlanoVisivel(moduloPlano: string[]): boolean {

    // SuperAdmin
    const usuario = this.localStorageObject.usuario;
    if (usuario?.superAdmin) { return true; }

    //Modulo Visivel
    if (this.podeExibirModuloPlano(moduloPlano)) {
      return true
    }
    else {
      if (constants.modulosPlanos[moduloPlano[0]][1] === 'invisivel') {
        return false
      }
    }

    return true;
  }

  quantidadeAcessoPlanoEmpresa(): number {
    let retorno = 0;

    const empresaSelecionada = this.localStorageObject.empresaSelecionada;

    retorno = empresaSelecionada?.planoEmpresa?.plano?.quantidadeAcesso ?? 0;

    if (empresaSelecionada?.planoEmpresa?.planoEmpresaItem) {
      empresaSelecionada.planoEmpresa.planoEmpresaItem.forEach(planoEmpresaItem => {
        if (planoEmpresaItem.tipo === 1) {
          retorno += planoEmpresaItem.quantidadeAcesso ? planoEmpresaItem.quantidadeAcesso : 0;
        }
      });
    }

    return retorno;
  }

  quantidadeFichaTecnicaPlanoEmpresa(): number {
    let retorno = 0;

    const empresaSelecionada = this.localStorageObject.empresaSelecionada;

    retorno = empresaSelecionada?.planoEmpresa?.plano?.quantidadeFichaTecnica ?? 0;

    if (empresaSelecionada?.planoEmpresa?.planoEmpresaItem) {
      empresaSelecionada.planoEmpresa.planoEmpresaItem.forEach(planoEmpresaItem => {
        if (planoEmpresaItem.tipo === 2) {
          retorno += planoEmpresaItem.quantidadeFichaTecnica ? planoEmpresaItem.quantidadeFichaTecnica : 0;
        }
      });
    }

    return retorno;
  }

  quantidadeMapaProducaoPlanoEmpresa(): number {
    let retorno = 0;

    const empresaSelecionada = this.localStorageObject.empresaSelecionada;

    retorno = empresaSelecionada?.planoEmpresa?.plano?.quantidadeMapaProducao ?? 0;

    if (empresaSelecionada?.planoEmpresa?.planoEmpresaItem) {
      empresaSelecionada.planoEmpresa.planoEmpresaItem.forEach(planoEmpresaItem => {
        if (planoEmpresaItem.tipo === 3) {
          retorno += planoEmpresaItem.quantidadeMapaProducao ? planoEmpresaItem.quantidadeMapaProducao : 0;
        }
      });
    }

    return retorno;
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, BlockUIService, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageObject } from '../services/localStorageObject.service';
import { Intercom } from 'ng-intercom';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly localStorageObject: LocalStorageObject,
    public intercom: Intercom
    ) { }

  logout(tokenExpirou?: boolean): void {
    this.blockUI.start();

    setTimeout(() => {
      localStorage.removeItem('rotulagem.token');
      localStorage.removeItem('rotulagem.expira');
      localStorage.removeItem('rotulagem.usuario');
      localStorage.removeItem('rotulagem.empresaSelecionada');
      localStorage.setItem('rotulagem.empresaSelecionada', JSON.stringify(null));
      localStorage.removeItem('rotulagem.sidebarScroll');
      localStorage.removeItem('intercom-state');

      this.localStorageObject.popularVariavelGlobalEmpresa();
      this.localStorageObject.popularVariavelGlobalUsuario();
      this.localStorageObject.popularVariavelGlobalToken();

      this.router.navigateByUrl('/auth/login');
      // this.intercom.shutdown();

      if (tokenExpirou) {
        this.toastr.warning('Sua sessão expirou, insira login e senha para continuar utilizando o sge.');
      }

      this.blockUI.reset();
    }, 1000);
  }

}

import constants from './constants';

const pageMenus = [
  {
    title: 'MENU USUÁRIO',
    isTitle: true
  },
  {
    icon: 'fas fa-th-large',
    title: 'Dashboard',
    url: '/dashboard'
  },
  {
    icon: 'fa fa-file-invoice',
    title: 'Cadastro',
    url: '',
    caret: true,
    submenu: [
      {
        title: 'Alimentos',
        url: '/alimentos/',
        moduloId: constants.MODULOS.ALIMENTOS,
        moduloPlano: constants.modulosPlanos.moduloAlimento
      },
      {
        title: 'Materiais',
        url: '/materiais-diversos/',
        moduloId: constants.MODULOS.MATERIAIS_DIVERSOS,
        moduloPlano: constants.modulosPlanos.moduloMaterialDiverso
      },
      {
        title: 'Fichas Técnicas',
        url: '/fichas-tecnicas/',
        moduloId: constants.MODULOS.FICHAS_TECNICAS,
        moduloPlano: constants.modulosPlanos.moduloFichaTecnica
      },
      {
        title: 'Produtos',
        url: '/produto/',
        moduloId: constants.MODULOS.PRODUTO,
        moduloPlano: constants.modulosPlanos.moduloProduto
      },
      {
        title: 'Cardápios',
        url: '/cardapio/',
        moduloId: constants.MODULOS.CARDAPIO,
        moduloPlano: constants.modulosPlanos.moduloCardapio
      },
      {
        title: 'Pedidos',
        url: '/pedido/',
        moduloId: constants.MODULOS.PEDIDO,
        moduloPlano: constants.modulosPlanos.moduloPedidos
      },
      {
        title: 'Fornecedores',
        url: '/cadastros/fornecedores/',
        moduloId: constants.MODULOS.FORNECEDORES,
        moduloPlano: constants.modulosPlanos.moduloFornecedores
      },
      {
        title: 'Clientes',
        url: '/cadastros/clientes/',
        moduloId: constants.MODULOS.CLIENTES,
        moduloPlano: constants.modulosPlanos.moduloClientes
      },
      {
        title: 'Tipos e Classes',
        url: '/cadastros/tipos-classes/',
        moduloId: constants.MODULOS.TIPOS_CLASSES,
        moduloPlano: constants.modulosPlanos.moduloTiposClasses
      }
    ]
  },
  {
    icon: 'fa fa-people-carry',
    title: 'Produção',
    url: '',
    caret: true,
    // caretRight: true,
    submenu: [
      {
        title: 'Mapa de Produção',
        url: '/mapa-producao/',
        moduloId: constants.MODULOS.MAPA_PRODUCAO,
        moduloPlano: constants.modulosPlanos.moduloMapaProducao
      },
      {
        title: 'Receitas Calculadas',
        url: '/mapa-producao/receitas-calculadas',
        moduloId: constants.MODULOS.MAPA_PRODUCAO,
        moduloPlano: constants.modulosPlanos.moduloReceitasCalculadas
      },
      {
        title: 'Execução',
        url: '/execucao/',
        moduloId: constants.MODULOS.EXECUCAO,
        moduloPlano: constants.modulosPlanos.moduloExecucao
      },
      {
        title: 'Expedição',
        url: '/expedicao/',
        moduloId: constants.MODULOS.EXPEDICAO,
        moduloPlano: constants.modulosPlanos.moduloExpedicao
      }
    ]
  },
  {
    icon: 'fa fa-dolly-flatbed',
    title: 'Estoque',
    url: '',
    caret: true,
    // caretRight: true,
    submenu: [
      {
        url: 'estoque/lista-movimentacoes-estoque',
        title: 'Movimentações',
        moduloId: constants.MODULOS.MOVIMENTACOES,
        moduloPlano: constants.modulosPlanos.moduloMovimentacao
      },
      {
        url: 'estoque/inventario',
        title: 'Inventário',
        moduloId: constants.MODULOS.INVENTARIO,
        moduloPlano: constants.modulosPlanos.moduloInventario
      }
    ]
  },
  {
    icon: 'fa fa-file-invoice-dollar',
    title: 'Compras',
    url: '',
    caret: true,
    // caretRight: true,
    submenu: [
      {
        url: 'compras/lista-compras',
        title: 'Lista de Compras',
        moduloId: constants.MODULOS.COMPRAS,
        moduloPlano: constants.modulosPlanos.moduloListaCompras
      },
      {
        url: 'compras/gerar-compras',
        title: 'Gerar Compras',
        moduloId: constants.MODULOS.COMPRAS,
        moduloPlano: constants.modulosPlanos.moduloGerarCompras
      }
    ]
  },
  {
    icon: 'fa fa-poll',
    title: 'Relatórios',
    url: '',
    caret: true,
    submenu: [
      {
        url: 'relatorios/estoque',
        title: 'Estoque',
        moduloId: constants.MODULOS.MOVIMENTACOES,
        moduloPlano: constants.modulosPlanos.moduloMovimentacao
      },
      {
        url: 'relatorios/execucao',
        title: 'Execução',
        moduloId: constants.MODULOS.EXECUCAO,
        moduloPlano: constants.modulosPlanos.moduloExecucao
      },
      {
        url: 'relatorios/mapa-producao',
        title: 'Mapa Produção',
        moduloId: constants.MODULOS.MAPA_PRODUCAO,
        moduloPlano: constants.modulosPlanos.moduloMapaProducao
      },
    ]
  },
  {
    icon: 'fa fa-users-cog',
    title: 'Painel Administrativo',
    url: '/painel-admin/gerenciar-empresa',
    caret: false,
    moduloId: constants.MODULOS.PAINEL_ADMIN,
  },
  {
    title: 'MENU CLIENTE',
    isTitle: true
  },
  {
    icon: 'fas fa-list',
    title: 'Cardápio',
    url: '/cardapio-cliente',
  },
];

export default pageMenus;

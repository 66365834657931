import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { forkJoin, lastValueFrom, Subscription } from 'rxjs';
import pageSettings from '../../src/app/core/config/page-settings';
import { LocalStorageObject } from './core/services/localStorageObject.service';
import { Intercom } from 'ng-intercom';
import { LoginService } from './core/services/login.service';
import { EmpresaService } from './core/services/empresa.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;

  subscription: Subscription;
  pageSettings;
  token: string;
  url: string;
  pageHasScroll: boolean;

  constructor(
    private readonly router: Router,
    private readonly localStorageObject: LocalStorageObject,
    private readonly intercom: Intercom,
    private readonly loginService: LoginService,
    private readonly empresaService: EmpresaService,
    ) {
    this.subscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (window.innerWidth < 768) {
          this.pageSettings.pageMobileSidebarToggled = false;
        }
      }
    });

    this.localStorageObject.popularVariavelGlobalEmpresa();
    this.localStorageObject.popularVariavelGlobalUsuario();
    this.localStorageObject.popularVariavelGlobalToken();
  }

  ngOnInit() {
    this.pageSettings = pageSettings;

    if (this.localStorageObject.empresaSelecionada && this.localStorageObject.usuario) {

      this.blockUI.start();
      forkJoin(
        [
          this.empresaService.obterPeloId(this.localStorageObject?.empresaSelecionada?.id),
          this.loginService.obterPorId(this.localStorageObject.usuario.id)
        ]
      ).subscribe(async response => {

        const usuarioEmpresaSelecionada = response[1].usuarioEmpresa.find(x => x.empresa.id === response[0].id);

        if (usuarioEmpresaSelecionada) {
          const tokenPermissoesEmpresa = await lastValueFrom(this.loginService.tokenPermissoesEmpresa(this.localStorageObject.usuario.id, usuarioEmpresaSelecionada.id));

          if (tokenPermissoesEmpresa) {
            response[1].permissoes = tokenPermissoesEmpresa?.permissoes;
            response[1].perfilId = tokenPermissoesEmpresa?.perfilId;
            response[1].perfilNome = tokenPermissoesEmpresa?.perfilNome;
            response[1].superAdmin = tokenPermissoesEmpresa?.superAdmin;

            localStorage.setItem('rotulagem.token', tokenPermissoesEmpresa?.access_token);
            localStorage.setItem('rotulagem.expira', tokenPermissoesEmpresa?.expires_in);
            this.localStorageObject.popularVariavelGlobalToken();
          }
        }

        localStorage.setItem('rotulagem.empresaSelecionada', JSON.stringify(response[0]));
        localStorage.setItem('rotulagem.usuario', JSON.stringify(response[1]));
        this.localStorageObject.popularVariavelGlobalEmpresa();
        this.localStorageObject.popularVariavelGlobalUsuario();

        //this.intercom.boot({
        //  app_id: 'myjflzle',
        //  // Supports all optional configuration.
        //  user_id: this.localStorageObject.usuario?.id,
        //  name: this.localStorageObject.usuario?.nome,
        //  email: this.localStorageObject.usuario?.email,
        //  phone: this.localStorageObject.usuario?.phoneNumber,
        //  plano: this.localStorageObject.empresaSelecionada?.planoEmpresa?.plano?.nome,
        //  empresa: this.localStorageObject.empresaSelecionada?.nomeFantasia,
        //  widget: {
        //    activator: '#intercom'
        //  }
        //});

        this.blockUI.stop();
      });
    }

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 0) {
      this.pageHasScroll = true;
    } else {
      this.pageHasScroll = false;
    }
  }

  // set page minified
  onToggleSidebarMinified(): void {
    if (this.pageSettings.pageSidebarMinified) {
      this.pageSettings.pageSidebarMinified = false;
    } else {
      this.pageSettings.pageSidebarMinified = true;
    }
  }

  // set page right collapse
  onToggleSidebarRight(): void {
    if (this.pageSettings.pageSidebarRightCollapsed) {
      this.pageSettings.pageSidebarRightCollapsed = false;
    } else {
      this.pageSettings.pageSidebarRightCollapsed = true;
    }
  }

  // hide mobile sidebar
  onHideMobileSidebar(): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      if (this.pageSettings.pageMobileSidebarFirstClicked) {
        this.pageSettings.pageMobileSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileSidebarToggled = false;
      }
    }
  }

  // toggle mobile sidebar
  onToggleMobileSidebar(): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      this.pageSettings.pageMobileSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileSidebarToggled = true;
      this.pageSettings.pageMobileSidebarFirstClicked = true;
    }
  }

  // hide right mobile sidebar
  onHideMobileRightSidebar(): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
        this.pageSettings.pageMobileRightSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileRightSidebarToggled = false;
      }
    }
  }

  // toggle right mobile sidebar
  onToggleMobileRightSidebar(): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      this.pageSettings.pageMobileRightSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileRightSidebarToggled = true;
      this.pageSettings.pageMobileRightSidebarFirstClicked = true;
    }
  }

  usuarioLogado(): boolean {
    return this.localStorageObject.token ? true : false;
  }
}




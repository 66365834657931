
<div>
  <ul class="navbar-nav navbar-right">
    <li class="dropdown navbar-user" ngbDropdown>
      <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
        <span *ngIf="usuarioComFoto(); else templateUrlFotoDropDown">
          <img alt="" src="assets/img/avatar-usuario_1.jpg">
        </span>
        <ng-template #templateUrlFotoDropDown>
          <img [src]="localStorageObject?.usuario.urlFoto" alt="" />
        </ng-template>
        <span class="d-none d-md-inline text-black"> {{localStorageObject?.usuario?.nome}} {{localStorageObject?.usuario?.sobrenome}}</span> <em class="caret  text-black"></em>
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <div class="dropdown-item-text text-center mb-2">
          <div class="text-center">
            <div *ngIf="usuarioComFoto(); else templateUrlFoto">
              <img alt="" class="img-thumbnail img-perfil" src="assets/img/avatar-usuario_1.jpg">
            </div>
            <ng-template #templateUrlFoto>
              <img class="img-thumbnail img-perfil" [src]="localStorageObject?.usuario.urlFoto" alt="" />
            </ng-template>
          </div>
          <div class="">
            <span class="font-weight-bolder text-success">{{localStorageObject?.usuario?.nome}} {{localStorageObject?.usuario?.sobrenome}}</span>
          </div>
          <div class="" *ngIf="localStorageObject?.empresaSelecionada || localStorageObject?.empresaSelecionada?.id === ''">
              <span class="small">{{localStorageObject?.usuario?.superAdmin ? 'Super Admin': localStorageObject?.usuario?.perfilNome}}</span>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <a href="javascript:;" class="dropdown-item" [routerLink]='["usuario"]'>
          <em class="fas fa-id-card"></em> Editar perfil</a>
        <div class="dropdown-divider"></div>
        <a href="javascript:;" class="dropdown-item" (click)="abrirModalListaEmpresa()">
          <em class="fas fa-exchange-alt"></em><span class=""> Trocar empresa</span>
        </a>
        <div class="dropdown-divider" *ngIf="localStorageObject?.usuario?.superAdmin"></div>
        <a href="javascript:;" class="dropdown-item" *ngIf="localStorageObject?.usuario?.superAdmin"
          [routerLink]="['/admin/clientes/lista-clientes']"><em class="fa fa-book-reader"></em> Painel SGE</a>
        <div class="dropdown-divider"></div>
        <a href="javascript:;" class="dropdown-item" (click)="authService.logout(false)"><em class="fa fa-sign-out-alt"></em> Sair</a>
      </div>
    </li>
  </ul>
</div>


<div class="modal fade" bsModal #staticModalEscolherEmpresa="bs-modal" [config]="config">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Trocar empresa selecionada</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="fecharModalTrocarEmpresa()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card card-nobordered">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <app-input label="Pesquisar por Razão social ou nome fantasia">
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" [(ngModel)]="nome" [ngModelOptions]="{standalone: true}"
                      (keyup.enter)="buscarPorNome()" placeholder="Digite a Razão social ou nome fantasia" />
                    <div class="input-group-append">
                      <button class="btn btn-success" type="button" id="" (click)="buscarPorNome()"><em
                          class="fa fa-search"></em></button>
                    </div>
                  </div>
                </app-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <app-table [totalItems]="listaUsuarioEmpresa.itensTotal" (alterouPagina)="alterarPagina($event)"
                  [isResponsive]="true" [tabelaComBorda]="true" [tabelaHover]="true" [tabelaSm]="false"  [itensPorPagina]="filtroUsuarioEmpresa.tamanhoPagina">
                  <thead>
                    <tr>
                      <th scope="row">Empresa</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let usuarioEmpresa of listaUsuarioEmpresa.itens; index as i">
                      <tr class="cursor-pointer" (click)="selecionarEmpresa(usuarioEmpresa)" [ngClass]="{'active': localStorageObject?.empresaSelecionada?.id === usuarioEmpresa?.empresa?.id}">
                        <td class="align-middle">
                          <div><strong>Razão Social:</strong> {{usuarioEmpresa?.empresa?.nome}}</div>
                          <div><small><strong>Nome Fantasia:</strong> {{usuarioEmpresa?.empresa?.nomeFantasia}}</small></div>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                  <tbody *ngIf="listaUsuarioEmpresa?.itens?.length === 0">
                    <tr>
                      <td colspan="3" class="text-center">Nenhum item na lista</td>
                    </tr>
                  </tbody>
                </app-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" (click)="fecharModalTrocarEmpresa()">
          <span class="fa fa-times"></span> Fechar
        </button>
      </div>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../utils';

@Pipe({
  name: 'unidadeMedida'
})
export class UnidadeMedidaPipe implements PipeTransform {

  transform(value: any, unidadeMedida: any, liquido: boolean): any {

    if (value === '-') {
      value = 0;
    }

    if (unidadeMedida === 'L' || unidadeMedida === 'kg') {
      value = value * 1000;
      if (liquido === true) {
        if (value < 1000) {
          value = (Math.trunc(value * 100) / 100).toString();
          value = value.replace('.', ',');
          value = value + ' mL';
        } else {
          value = ((Math.trunc(value * 100) / 100) / 1000).toString();
          value = value.replace('.', ',');
          value = value + ' L';
        }
      } else {
        if (value < 1000) {
          value = (Math.trunc(value * 100) / 100).toString();
          value = value.replace('.', ',');
          value = value + ' g';
        } else {
          value = ((Math.trunc(value * 100) / 100) / 1000).toString();
          value = value.replace('.', ',');
          value = value + ' kg';
        }
      }
    } else {
      if (liquido === true) {
        if (value < 1000) {
          value = (Math.trunc(value * 100) / 100).toString();
          value = value.replace('.', ',');
          value = value + ' mL';
        } else {
          value = ((Math.trunc(value * 100) / 100) / 1000).toString();
          value = value.replace('.', ',');
          value = value + ' L';
        }
      } else {
        if (value < 1000) {
          value = (Math.trunc(value * 100) / 100).toString();
          value = value.replace('.', ',');
          value = value + ' g';
        } else {
          value = ((Math.trunc(value * 100) / 100) / 1000).toString();
          value = value.replace('.', ',');
          value = value + ' kg';
        }
      }
    }

    return value;
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { Empresa } from 'src/app/shared/models/empresa';
import { TipoUsuarioEnum } from 'src/app/shared/models/enum/tipoUsuario.enum';
import { Usuario } from 'src/app/shared/models/usuario';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageObject extends BaseService {

  notificadorAlterarUsuario = new EventEmitter();
  notificadorAlterarEmpresa = new EventEmitter();
  notificadorAlterarToken = new EventEmitter();

  usuario: Usuario;
  empresaSelecionada: Empresa;
  token: string;

  constructor() {
    super();
  }

  notificarAlterarUsuario() {
    this.notificadorAlterarUsuario.emit();
  }

  notificarAlterarEmpresa() {
    this.notificadorAlterarEmpresa.emit();
  }

  notificarAlterarToken() {
    this.notificadorAlterarToken.emit();
  }

  popularVariavelGlobalUsuario(): void {
    this.usuario = this.obterUsuario();
    if (this.usuario) {
      this.notificarAlterarUsuario();
    }
  }

  popularVariavelGlobalEmpresa(): void {
    this.empresaSelecionada = this.obterEmpresaSelecionada();
    if (this.empresaSelecionada) {
      this.notificarAlterarEmpresa();
    }
  }

  popularVariavelGlobalToken(): void {
    this.token = this.obterTokenUsuario();
    if (this.token) {
      this.notificarAlterarToken();
    }
  }

  validarTipoUsuarioResponsavel(): boolean {
    let response = false;
    response = this.usuario?.tipoUsuario === TipoUsuarioEnum.ClienteResponsavel ? true : false;
    return response;
  }

}





